import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Gallery from "react-photo-gallery";
import Gap from "../elements/gap.tsx";
import Text from "../elements/text.tsx";
import ImageText from "../elements/imagetext.tsx";
import TextLayout from "../elements/textlayout.tsx";
import Annotation from "../elements/annotation.tsx";
import Title from "../elements/title.tsx";
import Images from "../elements/images.tsx";
import AliceCarousel from "react-alice-carousel";
import PhotoGallery from "../elements/gallery.tsx";
import "react-alice-carousel/lib/alice-carousel.css";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <TextLayout mdxType="TextLayout">
  <h4>Weave</h4>
  <h5>
    Discursive Design: Data physicalisation of invisible barriers faced by
    foreign residents navigating Dutch public systems
  </h5>
  <br />
  <br />
  2023 <br />
  Individual
  <br />
  Dutch Design Week, 2023
  <br />
  Keywords: discursive design, data physicalisation, cross-culture, participatory
  design, co-design, metaphors
  <iframe width="100%" height="500" src="https://www.youtube.com/embed/7MV5PSWcnLk?si=id21AcowWuaorBss" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen5></iframe>
    </TextLayout>
    <Gap margin={30} mdxType="Gap" />
    <PhotoGallery photos={[{
      src: "../../../fmp/demo10.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo2.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo1.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo11.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo12.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo3.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo7.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo6.jpeg",
      width: 3,
      height: 2
    }, {
      src: "../../../fmp/demo8.jpeg",
      width: 3,
      height: 2
    }]} mdxType="PhotoGallery" />
    <PhotoGallery photos={[]} mdxType="PhotoGallery" />
    <PhotoGallery photos={[]} mdxType="PhotoGallery" />
    <TextLayout mdxType="TextLayout">
  <Text mdxType="Text">
    I've spent the last 8 years living abroad, now I am one of 401,351 immigrants in the Netherlands (Statistics
     Netherlands, 2022). The feeling of being constantly lost and confused when navigating the system inspired me this project. I often thought to myself “If I could just speak fluent Dutch, all my troubles navigating the Dutch public systems would disappear”. However, When foreign-born adults navigate the Dutch public systems, <strong>language was the most visible barriers, tip of the iceberg, while underneath the surface, there lies many invisible barriers, such as visa, cultural differences, local social network, connection with local news, politics etc. </strong>
    <br /><br />
    Weave is a project that aim to raise the awareness of those invisible barriers through data physicalisation, and provide a space for audience to gain exposure to information, introspect, and engage in discussions. Participantes are invited to interact with the exhibit, contributing to the data map. People can pick a thread corresponding to their country of origin, and thread individual responses to each question. Upon finishing, they can wear the special glasses that unveil a deeper meaning of each question.
        <br />
        <br />
  The project employs metaphors to help audiences interpret the discursive
  artefact. The more challenging options are placed higher to symbolise the
  effort required to climb over these metaphorical mountains. The entire
  design's shape is alike an iceberg, linking to the cultural iceberg model. 
  The deeper audience go down the iceberg, the more
  invisible the barriers are. The glasses symbolises a shift in perspective,
  inviting viewers to put themselves in others' shoes and seeing from others'
  eyes, as all the hidden sentences start with “when you are”.
      </Text>
      <img src="../../../fmp/theory.png" />
      <Title mdxType="Title">
  <h3>Design Process</h3>
      </Title>
      <img src="../../../fmp/process.png" />
      <Annotation mdxType="Annotation">
  Triple Diamond including three stages: define, design, develop
      </Annotation>
      <Text title="Define" mdxType="Text">
  The first stage centered on the question{" "}
  <strong>
    "What are the underlying barriers faced by foreign-residents when they
    navigating the Dutch public system and how are these interconnected at a
    systemic level?"
  </strong>{" "}
  The activities involved in this stage include relation mapping, eight
  semi-structured interviews with 5 foreign residents and 3 Dutch residents.
  <br />
  <br />
  <AliceCarousel animationDuration="2000" infinite controlsStrategy="alternate" autoPlay mouseTracking disableButtonsControls items={[<img src="../../../fmp/Interview1.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/Interview2.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/Interview3.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />]} mdxType="AliceCarousel" />
  <Annotation mdxType="Annotation">Semi-structured interviews</Annotation>
  <img src="../../../fmp/theory1.png" />
  <Annotation mdxType="Annotation">
    Conflict analysis map developed by the designer. Cultural differences were
    identified as conflicting with building a local social network and
    understanding the Dutch public system. However, the social network serves as
    a facilitator for acquiring relevant information, which aids in navigating
    the public system.
  </Annotation>
  Based on this conflict map, a clear distinction between Dutch-born residents and
  foreign residents emerged. Generally speaking, Dutch residents encounter fewer
  cultural differences and have a bigger social network, which is highly beneficial
  in navigating the system. Contrarily, foreign residents typically face more significant
  cultural differences and have a smaller social network, which hinder the navigation
  process.
      </Text>
      <Gap margin={100} mdxType="Gap" />
      <Text title="Design" mdxType="Text">
  The second stage concentrates on the question{" "}
  <strong>
    "How can a discursive design artefact to be developed to address the design
    challenge?"
  </strong>{" "}
  and{" "}
  <strong>
    "How effective is the discursive artefact in terms of communication,
    awareness-raising, and audience reflection?"
  </strong>
  . The activities involves ideation, sketching, user test with a low-fidelity prototype
  and qualitative data analysis.
  <br />
  <br />
  <AliceCarousel animationDuration="2000" infinite autoPlay controlsStrategy="alternate" mouseTracking items={[<img src="../../../fmp/idea3.png" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/idea1.png" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/idea2.png" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/idea4.png" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />]} mdxType="AliceCarousel" />
  <Annotation mdxType="Annotation">Four ideations</Annotation>
  <AliceCarousel animationDuration="2000" infinite autoPlay mouseTracking controlsStrategy="alternate" items={[<img src="../../../fmp/lowfi1.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/lowfi2.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/lowfi3.png" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />]} mdxType="AliceCarousel" />
  <Annotation mdxType="Annotation">
    Exploring different forms of data physicalisation and the threading method
    through the mountains
  </Annotation>
  <AliceCarousel animationDuration="2000" infinite autoPlay mouseTracking controlsStrategy="alternate" items={[<img src="../../../fmp/testing4.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/testing5.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />, <img src="../../../fmp/testing6.jpg" onDragStart={e => {
          e.preventDefault();
        }} role="presentation" />]} mdxType="AliceCarousel" />
  <Annotation mdxType="Annotation">Low Fidelity Prototype User Test</Annotation>
      </Text>
      <Gap margin={100} mdxType="Gap" />
      <Text title="Develop" mdxType="Text">
  The final stage addresses the question{" "}
  <strong>
    "How can the aesthetics and interaction of the current concept be improved
    to construct a museum quality prototype?"
  </strong>
  . This improvement is aimed at attracting more participation, thus solidifying
  data patterns and increasing impact. Activities in this stage involved multiple
  iterations of form, material, interface exploration, as well as making.
      </Text>
      <img src="../../../fmp/highfi1.jpg" />
      <Annotation mdxType="Annotation">Attaching the reference image to the wood base</Annotation>
      <img src="../../../fmp/highfi2.jpg" />
      <Annotation mdxType="Annotation">Cutting the outer shape</Annotation>
      <img src="../../../fmp/highfi3.jpg" />
      <Annotation mdxType="Annotation">Cutting the slots and painting the edge</Annotation>
      <img src="../../../fmp/highfi4.jpg" />
      <img src="../../../fmp/highfi8.jpg" />
      <Annotation mdxType="Annotation">Assembling</Annotation>
      <AliceCarousel animationDuration="2000" autoPlay infinite controlsStrategy="alternate" mouseTracking items={[<img src="../../../fmp/highfi5.jpg" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../fmp/highfi11.jpg" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../fmp/highfi7.jpg" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../fmp/highfi14.jpg" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../fmp/highfi9.jpg" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />, <img src="../../../fmp/highfi12.jpg" onDragStart={e => {
        e.preventDefault();
      }} role="presentation" />]} mdxType="AliceCarousel" />
      <Annotation mdxType="Annotation">Exploration of other parts of the artefact</Annotation>
      <Title mdxType="Title">
  <h3>Theory behind Questions</h3>
      </Title>
      <img src="../../../fmp/theory3.png" />
      <Text mdxType="Text">
  Based on the literature, interviews and personal experience,{" "}
  <strong>
    7 representative questions are chose to visualise the invisible barriers
    that foreign residents experience in the Netherlands
  </strong>
  :<ul>
    <li>How fluent is your Dutch?</li>
    <li>
      How different do you find the Dutch culture compared to your home
      environment?
    </li>
    <li>
      How concerned are you about your current visa status in the Netherlands?
    </li>
    <li>
      How many Dutch locals do you have as close friends in where you live?
    </li>
    <li>
      In challenging situations, such as dealing with tax matters, what is your
      first resource?
    </li>
    <li>How interested are you in discussing Dutch news with others?</li>
    <li>
      How interested are you in participating in Dutch elections if you are
      eligible to do so?
    </li>
  </ul>
      </Text>
      <Text mdxType="Text">
  The theory associated with each question are:
  <ul>
    <li>
      <strong>How fluent is your Dutch?</strong>
    </li>{" "}
    The language barrier is visible and the first barrier for foreign-born
    residents. Language is a key vehicle for migrant to access and experience
    services of the host society smoothly and the competency facilitates the
    sense of belonging (Erlinghagen et al., 2021). People generally need to rely
    on others or tools to navigate.
    <br />
    <br />
    <li>
      <strong>
        How different do you find the Dutch culture compared to your home
        environment?
      </strong>
    </li>
    Cultural distance is defined as the degree to which shared norms and values
    differ from one country to another (Hofstede, 2001), which influences the
    design of public service system. Sociocultural adaptation, number of close
    friends and perceived cultural distance are interrelated. The larger the
    difference between two cultures, the migrant needs to expend more cognitive
    efforts in adapting to the difference (Demes & Geeraert, 2014) and is likely
    to have less close friends (Erlinghagen et al., 2021). Meanwhile, 90% of
    cultural characteristics, such as non-verbal communication or values, are
    difficult to identify and easy to ignore (Lachner et al., 2015) and can
    easily become a significant invisible barrier.
    <br />
    <br />
    <li>
      <strong>
        How concerned are you about your current visa status in the Netherlands?
      </strong>
    </li>
    Although most of interview participants are considered as highly skilled
    immigrant, they still reported the fear of dealing with government and the
    insecurity in the system. The possibility of having to leave the country
    without any influence may lead to hesitation in fully engaging in public
    affairs or advocating for the rights due to concerns about the potential
    impact on the visa. The subconscious fear invisibly hindered foreign-born
    residents gain the familiarity with the system through trial and error.
    <br />
    <br />
    <li>
      <strong>
        How many Dutch locals do you have as close friends in where you live?
      </strong>
    </li>
    Friendships are indispensable to individuals’ opportunities and to their
    integration into the society (Erlinghagen et al., 2021). Additionally,
    locals have more information resources within the society. For example,
    locals have more knowledge in applying for benefits and solving certain
    issues, like housing or tax. Having strong social network with the locals
    makes it easier for migrant to navigate the system and have access to
    broader opportunities in the emigration country (Erlinghagen et al., 2021).
    <br />
    <br />
    <li>
      <strong>
        In challenging situations, such as dealing with tax matters, what is
        your first resource?
      </strong>
    </li>
    Both Dutch and foreign residents struggled with bureaucracy and jargon when
    dealing with taxes. However, many Dutch natives first seek help from
    parents, relatives, partners, or close friends. On the other hand, foreign
    young adults typically translate Dutch documents or search for solutions
    online as their initial response. Migrants, especially new arrivals, are
    less likely to have family and strong ties in the host country. Handling tax
    issues requires support from strong ties due to the sensitive and personal
    nature of the information involved, which might be uncomfortable to share
    with weak ties (Erlinghagen et al., 2021). Failure to address the
    bureaucratic task can lead to feelings of loneliness and powerlessness.
    <br />
    <br />
    <li>
      <strong>
        How interested are you in discussing Dutch news with others?
      </strong>
    </li>
    The significance of the local news is underestimated. Local news is an
    important channel for people to access information source. Keeping update to
    the news and having a social discussion with others are important for people
    to have access to relevant information sources and feel socially connected.
    <br />
    <br />
    <li>
      <strong>
        {" "}
        How interested are you in participating in Dutch elections if you are eligible
        to do so?
      </strong>
    </li>
    Although there are legislation prohibiting foreign residents from voting,
    the clause "if you are eligible to do so" was added to focus on the pure
    motivation aspect of voting. This question focuses on connecting with the
    society on a next level, and being represented in the country’s decision
    making. It serves as an end question because all previous answers
    potentially influence the response to this last question.
    <br />
    <br />
  </ul>
      </Text>
    </TextLayout>
    <Gap margin={100} mdxType="Gap" />
    <hr />
    <h5>{`References:`}</h5>
    <div>
  Demes, K. A., & Geeraert, N. (2014). Measures Matter: Scales for Adaptation,
  Cultural Distance, and Acculturation Orientation Revisited. Journal of
  Cross-Cultural Psychology, 45(1), 91–109.
  https://doi.org/10.1177/0022022113487590
    </div>
    <br />
    <div>
  Statistics Netherlands (2022). How many people immigrate to the Netherlands?
  Statistics Netherlands.
  https://www.cbs.nl/en-gb/dossier/migration-and-integration/how-many-people-immigrate-to-the-netherlands-
    </div>
    <br />
    <div>
  Tharp, B. M., & Tharp, S. M. (2019). Discursive Design: Critical, Speculative,
  and Alternative Things. Amsterdam University Press.
    </div>
    <br />
    <div>
  Lachner, F., von Saucken, C., ‘Floyd’ Mueller, F., & Lindemann, U. (2015).
  Cross-Cultural User Experience Design Helping Product Designers to Consider
  Cultural Differences. In P. L. P. Rau (Ed.), Cross-Cultural Design Methods,
  Practice and Impact (Vol. 9180, pp. 58–70). Springer International Publishing.
  https://doi.org/10.1007/978-3-319-20907-4_6
    </div>
    <br />
    <div>
  Erlinghagen, M., Ette, A., Schneider, N. F., & Witte, N. (Eds.). (2021). The
  Global Lives of German Migrants: Consequences of International Migration
  Across the Life Course. Springer International Publishing.
  https://doi.org/10.1007/978-3-030-67498-4
    </div>
    <br />
    <div>
  Herbert, S. (2017). Conflict analysis: Topic guide. Birmingham, UK: GSDRC,
  University of Birmingham.
    </div>
    <br />
    <div>
  Hofstede, G. (2001). Culture’s Consequences: Comparing Values, Behaviors,
  Institutions and Organizations Across Nations. SAGE.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      